import React from 'react';
import { get } from 'lodash';
import CircularProgress from "@myaccount/progress/CircularProgress";
import ContactModule from '@myaccount/contactModule';
import SimpleContactModule from '@myaccount/contactModule/simpleContact';

const getPhoneNumber = (str) => {
    return str.replace(/\s+/g, '')
}

const ContactModuleComp = (props) => {
    if (!props?.data)
        return <></> // @todo some reason i may display loading not sure why need to check

    const modalTitle = `Contact ${get(props, 'data.name', 'your agent')}`;
    const moduleTitle  = props?.moduleTitle || 'Contact your agent';
    const { mobile_phone, email } = props.data;

    let data = {
        ...props.data,
        avatar: true,
        btns:[]
    }
    if (mobile_phone) {
        data.btns.push({
            telLabel: "Call me",
            tel: getPhoneNumber(mobile_phone)
        })
        data.btns.push({
            whatsappLabel: "Whatsapp",
            whatsappUrl: getPhoneNumber(mobile_phone)
        })
    }
    if (email) {
        data.btns.push({
            emailLabel: "Message me",
            email: email
        })
    }
    if (false) {
        data.btns.push({
            "videoAskLabel": "Book a Video Call",
            "videoAskUrl": "#",
        })
    }
    if (!data.btns.length) {
        data.btns.push({
            emailLabel: "Contact Us"
        })
    }
    return(
        <>
            {props?.simpleContact ? (
                <SimpleContactModule
                    data={data}
                    negotiator_id={props?.crm_id}
                    modalTitle={modalTitle}
                />
            ) : (
                <ContactModule
                    theme={props.theme}
                    data={data}
                    negotiator_id={props?.data?.crm_id}
                    moduleTitle={moduleTitle}
                    modalTitle={modalTitle}
                />
            )}
        </>
    )
}

export default ContactModuleComp
