export default {
    "props": {
        "showTel": true,
        "showEmail": true,
        "showVideoAsk": true,
        "showWhatsapp": true,
        "maxDialogW": "sm",
        "cardModule": {
            "elevation": 2,
        },
        "cardContent": {

        },
        "cardModuleTitle": {
            "variant": "h5",
        },
        "cardActions": {
            "disableSpacing": true,
        },
        "cardModuleUserTitle": {
            "component": "p",
            "variant": "bodyXLarge"
        },
        "cardModuleUserJobTitle": {
            "component": "p",
            "variant": "bodySmall"
        },
        "cardModuleButtonTelProps": {
            "variant": "contained",
            "color": "primary",
        },
        "cardModuleButtonEmailProps": {
            "variant": "contained",
            "color": "primary",
        },
        "cardModuleButtonVideoaskProps": {
            "variant": "contained",
            "color": "primary",
        },
        "cardModuleButtonWhatsappProps": {
            "variant": "contained",
            "color": "primary",
        },
        "avatar": {
        },
        "closeCard": {
            "component": "p",
            "variant": "bodySmall",
        },
        "closeCardData": {
            // "closeLabel": "No thanks",
        },
        "_data": {
            "name": "Ellie Wilkinson",
            "job_title": "Sales Advisor",
            "avatar": "/broken-image.jpg",
            "btns": [
                {
                    "id": "34",
                    "telLabel": "Call me",
                    "tel": "49823809234",
                },
                {
                    "id": "53",
                    "emailLabel": "Message us",
                    "email": "email@email.com",
                },
                {
                    "id": "63",
                    "videoAskLabel": "Book a Video Call",
                    "videoAskUrl": "#",
                },
                {
                    "id": "84",
                    "whatsappLabel": "Whatsapp",
                    "whatsappUrl": "#",
                },
            ],
        },
    },
    "sx": (theme) => ({
        "& .contactModule": {
            "padding": theme.modulePadding,
            "borderRadius": theme.moduleBorderRadius,
        },
        "& .cardContent": {
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "padding": 0,
            "textAlign": "center",
        },
        "& .cardModuleTitle": {
            "marginBottom": theme.spacing(4),
        },
        "& .cardActions": {
            "flexDirection": "column",
            "padding": 0,
            "marginTop": theme.spacing(3),
            "& > div": {
                "width": "100%",
            }
        },
        "& .cardModuleUserTitle": {
            "color": theme.palette.text.primary,
        },
        "& .cardModuleUserJobTitle": {
            "color": theme.palette.text.primary,
        },
        "& .cardModuleButton": {
            "width": "100%",
            "marginTop": theme.spacing(2),
        },
        "& .avatar": {
            "marginBottom": theme.spacing(2),
            // "backgroundColor": "#ff0000",
            "width": 80,
            "height": 80,
        },
        "& .closeCard": {
            "color": theme.palette.grey.grey2,
            "marginTop": theme.spacing(3),
        },
    }),
}
