import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import ComponentLayout from '@myaccount/ComponentLayout';
import ContactModule from '@myaccount/contactModule';
import ActionModal from '@myaccount/modals/action';

import defaults from './simpleContactModuleDefaults';

const SimpleContactModule = withSubtheme((props) => {
    const {
        className,
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        data,
        modalTitle,

        cardModule,
        cardContent,
        cardModuleUserTitle,
        cardModuleUserJobTitle,
        cardModuleUserHelpText,
        cardModuleUserHelpTextLink,
        avatar,
        _data
    } = props;

    let moduleData = data ? data : _data;

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (value) => {
        value.preventDefault();
        setOpen(true);
    };
    const handleClose = (value) => {
        value.preventDefault();
        setOpen(false);
    };

    let avatarSrc = moduleData?.profile_img;

    return(
        <ComponentLayout
            className={className}
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Card classes={{root: "cardModule"}} {...cardModule}>
                <CardContent classes={{root: "cardContent"}} {...cardContent} onClick={handleClickOpen}>
                    {moduleData?.avatar &&
                        <Avatar alt={moduleData.name} src={avatarSrc} classes={{root: "avatar"}} {...avatar} />
                    }
                    <Box>
                        {moduleData?.name &&
                            <Typography classes={{root: "cardModuleUserTitle"}} {...cardModuleUserTitle}>
                                {moduleData.name}
                            </Typography>
                        }
                        {moduleData?.job_title &&
                            <Typography classes={{root: "cardModuleUserJobTitle"}} {...cardModuleUserJobTitle}>
                                {moduleData.job_title}
                            </Typography>
                        }
                        {moduleData?.helpText &&
                            <Typography classes={{root: "cardModuleUserHelpText"}} {...cardModuleUserHelpText}>
                                {moduleData.helpText}{moduleData?.helpTextLink && <Link href="#" classes={{root: "cardModuleUserHelpTextLink"}} onClick={handleClickOpen} {...cardModuleUserHelpTextLink}>{data.helpTextLink}</Link>}
                            </Typography>
                        }
                    </Box>
                </CardContent>
            </Card>
            <ActionModal
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={<ContactModule data={data} />}
                title={modalTitle ? modalTitle : "Contact"}
            />
        </ComponentLayout>
    )
}, 'simpleContactModule', defaults)

export default SimpleContactModule
