import * as React from 'react';
import PropTypes from 'prop-types';
import { withSubtheme } from '@starberryComponentsMui';
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Actions from '@myaccount/actions';
import { getCurrentTab } from "@myaccount/helper"

import defaults from './defaults';

// My Account
import clsx from "clsx"

function TabPanel(props) {
    const {
        children,
        value,
        index,
        tabPanel,
        tabPanelBox,
        className,
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            className={className}
            {...tabPanel}
        >
            {value === index && (
                <Box sx={tabPanelBox} className={"tabsMyAccount-tabPanelBox"}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const TabsWrappedLabel = withSubtheme(props => {
    const {
        className,
        fullWidthTabs,
        theme,

        tabsContainer,
        tabs,
        tab,
        tabPanel,
        tabPanelBox,
        data,
        _data,
        manageValue,
        tabItems,
        addUrl,
        tabLevel = 1
    } = props

    let tabsData = data ? data : _data;

    let initialValue = manageValue ? manageValue : "tab1";

    // if addUrl and tablists then change initialValue value to when it is getting re render
    if (tabItems && addUrl) {
        initialValue = getCurrentTab(initialValue, tabItems)
    }

    const [value, setValue] = React.useState(initialValue);
    const handleChange = React.useCallback((event, initialValue) => {
        setValue(initialValue);
        if (addUrl) {
            let url = `${window.location.pathname}#${initialValue}`;
            window.history.pushState({}, '', url)
        }
    }, [addUrl]);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    // we can override the tabs variant if we pass it through props
    const tabProps = {
        ...tabs,
        ...isSmallScreen && fullWidthTabs && { variant: "fullWidth" }
    }

    return (
        <div className={className} {...tabsContainer}>
            <AppBar position="static" color="transparent" elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                        root: `tabsMyAccount-tabs tabLevel-${tabLevel}`,
                        indicator: "tabsMyAccount-indicator",
                        scrollButtons: "tabsMyAccount-scrollButtons"
                    }}
                    {...tabProps}
                >
                    {tabsData.map(tabData => {
                        return(
                            <Tab
                                key={tabData.id}
                                value={tabData.id}
                                label={tabData.name}
                                classes={{
                                    root: clsx("tabsMyAccount-tab", "tabsMyAccount-wrapper"),
                                    selected: "tabsMyAccount-selected",
                                }}
                                {...a11yProps(`${tabData.id}`)}
                                {...tab}
                                onClick={tabData?.customOnClick}
                            />
                        )
                    })}
                </Tabs>
            </AppBar>
            {tabsData.map(tab =>
                <TabPanel
                    key={tab.id}
                    value={value}
                    index={tab.id}
                    className={"tabsMyAccount-tabPanel"}
                    tabPanelBox={tabPanelBox}
                    {...tabPanel}
                >
                    {tab.content ? tab.content : <Actions />}
                </TabPanel>
            )}
        </div>
    )
}, 'tabsMyAccount', defaults)

export default TabsWrappedLabel
