export default {
    "props": {
        "cardModule": {
            "elevation": 0,
        },
        "cardContent": {
        },
        "cardModuleUserTitle": {
            "component": "h6",
            "variant": "bodySmall"
        },
        "cardModuleUserJobTitle": {
            "component": "p",
            "variant": "bodyXSmall"
        },
        "cardModuleUserHelpText": {
            "component": "p",
            "variant": "bodyXSmall"
        },
        "cardModuleUserHelpTextLink": {
            // "component": "button", // If a link doesn't have a meaningful href, it should be rendered using a <button> element.
            "color": "primary",
            "variant": "inherit",
        },
        "avatar": {
        },
        "_data": {
            "name": "Ellie Wilkinson",
            "jobTitle": "Sales Advisor",
            "avatar": "/broken-image.jpg",
            "helpTextLink": "Get in touch",
            "helpText": "Need help? ",
            "btns": [
                {
                    "id": "34",
                    "telLabel": "Call me",
                    "tel": "49823809234",
                },
                {
                    "id": "53",
                    "emailLabel": "Message us",
                    "email": "email@email.com",
                },
                {
                    "id": "63",
                    "videoAskLabel": "Book a Video Call",
                    "videoAskUrl": "#",
                },
                {
                    "id": "84",
                    "whatsappLabel": "Whatsapp",
                    "whatsappUrl": "#",
                },
            ],
        },
    },
    "sx": (theme) => ({
        // "padding": theme.modulePadding,
        // "borderRadius": theme.moduleBorderRadius,
        "& .cardModule": {

        },
        "& .cardContent": {
            "display": "flex",
            "flexDirection": "row",
            "alignItems": "center",
            "padding": 0,
            "&:last-child": {
                "paddingBottom": 0,
            },
        },
        "& .cardModuleUserTitle": {
            "color": theme.palette.text.primary,
        },
        "& .cardModuleUserJobTitle": {
            "color": theme.palette.grey.grey2,
            "marginBottom": theme.spacing(0.5),
        },
        "& .cardModuleUserHelpText": {
            "color": theme.palette.text.primary,
        },
        "& .cardModuleUserHelpTextLink": {
            // "color": theme.palette.text.primary,
        },
        "& .avatar": {
            "width": 64,
            "height": 64,
            "marginRight": theme.spacing(2),
        }
    }),
}
