export default {
    "props": {
        "pageTitleProps": {
            "component": "h1",
            "variant": "h3",
        },
    },
    "sx": (theme) => ({
        "display": "none",
        "fontWeight": "normal",
        [theme.breakpoints.up('md')]: {
            "display": "block",
            "marginBottom": `${theme.spacing(4)} !important`,
        },
    }),
}